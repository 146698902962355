import { AcUnit } from "@mui/icons-material";
import React from "react";
import "./Business.css";
import Footer from "./Footer";
import Header from "./Header";

const Business = ()=>{
    return(
        <>
        <Header/>
        <div className="business">
            <div className="business-bg">
                <div className="business-text">
                    <h1>Business Process Automation & Management Solutions</h1>
                </div>
            </div>
            <div className="business-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Business process automation and management solutions involve the use of technology to streamline and optimize business processes. These solutions can help organizations to reduce errors, improve efficiency, and increase productivity by automating repetitive or manual tasks.
                </p>
                <h5>
                Some examples of tasks that may be automated using business process automation and management solutions include:
                </h5>
                <div className="business-box1">
             <div className="business-ul" data-aos="flip-left">
                   <div className="business-li"><h6><AcUnit className='ac'/>
                    Data entry and management</h6></div>
                    <div className="business-li"><h6><AcUnit className='ac'/>
                    Invoice and payment processing</h6></div> 
                    <div className="business-li"><h6><AcUnit className='ac'/>
                    Customer service and support</h6></div> 
                    <div className="business-li"><h6><AcUnit className='ac'/>
                    Human resources and payroll management</h6></div> 
                    <div className="business-li"><h6><AcUnit className='ac'/>
                    Project and task management</h6></div>
                     <div className="business-li"><h6><AcUnit className='ac'/>
                     Supply chain and logistics management</h6></div>
             </div>
             </div>
             <p>
             There are many different types of business process automation and management solutions available, including software applications, workflow tools, and artificial intelligence (AI) and machine learning systems. These solutions can be customized to meet the specific needs and goals of an organization.
             </p>
             <p>
             In addition to automating tasks, business process management solutions may also include tools for monitoring and analyzing business processes to identify opportunities for improvement and optimize performance.
             </p>

            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Business;