import { AcUnit } from '@mui/icons-material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "./ManageService.css";

const ManageService= ()=>{

    return(
        <>
        <Header/>
        <div className="manageservice">
        <div className="manageservice-bg">
                <div className="manageservice-text">
                    <h1>Manage Service</h1>
                </div>
            </div>
            <div className="manageservice-box">
                <h5 data-aos="fade-up">Managed IT Services for MSME </h5>
               <div className="bord">
                <span></span>
               </div>
               <h2>
               ITSC managed IT services can help eliminate your company's technology-based headaches.
               </h2>
               <p>
               Do you depend on technology to maximize the efficiency of your business? Has that technology 
               ever let you down? If you are like other businesses, you have your fair share of horror 
               stories. Whether you have tales of downtime brought on by computer or network problems, or 
               simply don’t like how much your organization is paying to keep your IT up and running, Coleman  
               Technologies is here to produce results. Our flat-rate service plan will deliver your company 
               enterprise-level IT support and solutions that are tailor-made to relieve all of your 
               organization’s technology-induced pain points.
               </p>
               <div className="bord">
                <span></span>
               </div>
            </div>
              <div className="manageservice-box1"data-aos="flip-left">
                <div className='ul1'>
                    <div className='LI'><h4><AcUnit className="ac"/>Budget Your IT</h4></div>
                    <div className='LI'><h4><AcUnit className="ac"/>Reduce Costly Downtime</h4></div>
                    <div className='LI'><h4><AcUnit className="ac"/>Reduce Costly Downtime</h4></div>
                </div>   
                   
              </div>
              <div className="manageservice-box2">
                <h2>More Services:</h2>
                             <NavLink exact to="/amc"style={{textDecoration: "none"}}>
                                <div className="manageservice-block">
                                    <img src={require("../images/annual.png")} alt="service"/>
                                    <h5>AMC(Annual Maintenance Contract)</h5>
                                </div>
                                </NavLink>
                                
              </div>
        </div>

        <Footer/>
        </>
    )
}

export default ManageService;