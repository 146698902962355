import React from "react";
import "./Laptop.css";

const Laptop = ()=>{
    return(
        <>
           <div className="laptop">
            <div className="laptop-bg">
                <div className="laptop-text">
                    <h1>Laptop</h1>
                </div>
            </div>
           </div>
        </>
    )
}
export default Laptop;