import React from "react";
import "./Patners.css";

const Patners =()=>{

    return(
        <>
        <div className="partner">
            <h3>Our Partners</h3>
              <div className="partner-border"></div>

                <div className="partner-wrapper">
                   <div className="partner-slider">
                      <div className="partner-track">

                        <div className="partner-block">
                            <img src={require("../images/Dell.jpg")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/cisco-logo.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/asus.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/hp-logo.jpeg")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/intel.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Microsoft.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Apple-Logo.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/google.png")} alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/oracle.png")} alt="patners"/>
                        </div>

                    </div>
                   </div>
              </div>
            
        </div>
        </>
    )
}
export default Patners;