import { AcUnit } from "@mui/icons-material";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Infrastructure.css";
import { NavLink } from "react-router-dom";

const Infrastructure= ()=>{
    return(
        <>
        <Header/>
         <div className="infra">
            <div className="infra-bg">
                <div className="infra-text">
                    <h1>Infrastructure Support Services</h1>
                </div>
            </div>
            <div className="infra-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Organizational difficulties that businesses have with their IT infrastructure often leave them with difficulties and deficits that hinder overall operational effectiveness. For this reason companies need to have a strategy in place when setting up their business’ computing infrastructure. When searching for the right company to help you set up and manage your IT infrastructure, you have to take into account the company’s competence in keeping your office organized.
                </p>
                <p>
                At ITSC, our services promote the implementation and management of a coordinated, efficient, and dynamic computing infrastructure that is designed to take your company where you want it to go. Our infrastructure strategy is made up of four crucial variables that are designed to provide organizations with a clean and dynamic computing infrastructure.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            <div className="infra-box1"data-aos="flip-left">
                <div className="ul1">
                    <div className="LI"><h5><AcUnit className="ac"/>Battery Backup</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Cable Management</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Server Rack Design</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Server Room Design</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Infrastructure Support Services</h5></div>
                </div>
            </div>
            <div className="infra-box2">
                <h2> More Services:</h2>
                <div className="infra-row">
                        <NavLink exact to="/it"style={{textDecoration: "none"}}>
                            <div className="infra-block">
                                <img src={require("../images/security.png")} alt="service"/>
                                <h5>IT Security Service</h5>
                            </div>
                        </NavLink>

                        <NavLink exact to="/IT-Asset"style={{textDecoration: "none"}}>
                            <div className="infra-block1">
                                <img src={require("../images/monitoring.png")} alt="service"/>
                                <h5>IT Asset Management Services</h5>
                            </div>
                        </NavLink>
                </div>           
              </div>
         </div>
         <Footer/>
        </>
    )
}
export default Infrastructure;