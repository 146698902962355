import React from "react";
import "./Home.css";
import bgVideo from '../video/black.mp4'
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import { ArrowDropDown, ArrowRight, Menu } from "@mui/icons-material";
import Patners from "./Patners";

const Home = () => {

    return (
        <>
            <div > 
                <div >
                <video src={bgVideo} autoPlay loop muted  className="setvideo"/>
                </div>
                <div >
                
                <nav className="abc">
                <div>
                    <img src={require("../images/pentagon.png")} className="logo" alt="logo" />
                </div>
                    <ul>
                        <NavLink exact to="/" style={{ textDecoration: "none" }}><li><h4>Home</h4></li></NavLink>
                        <NavLink exact to="/about" style={{ textDecoration: "none" }}><li><h4>About Us</h4></li></NavLink>
                        <NavLink exact to="/services" style={{ textDecoration: "none" }}><li><h4>Services</h4>
                            <ul className="ul">
                                <NavLink exact to="/manageService" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Managed </h5></li></NavLink>
                                <NavLink exact to="/comprehensive" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Comprehensive </h5></li></NavLink>
                                <NavLink exact to="/remote-support" style={{ textDecoration: "none" }}> <li><h5><ArrowRight />Remote </h5></li></NavLink>
                                <NavLink exact to="/It-Consulting" style={{ textDecoration: "none" }}><li><h5><ArrowRight />IT Consulting</h5></li></NavLink>
                                <NavLink exact to="/It-Vendor" style={{ textDecoration: "none" }}><li><h5><ArrowRight />IT Vendor </h5></li></NavLink>
                                <NavLink exact to="/infrastructure-support" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Infrastructure </h5></li></NavLink>
                                <NavLink exact to="/professionalserver" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Professional </h5></li></NavLink>
                                <NavLink exact to="/staffing" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Staffing </h5></li></NavLink>
                            </ul>
                        </li></NavLink>
                        <NavLink exact to="/solution" style={{ textDecoration: "none" }}><li><h4>Solutions</h4></li></NavLink>
                        <NavLink exact to="/industries" style={{ textDecoration: "none" }} ><li><h4>Industries</h4>
                            <ul className="ul" >
                                <NavLink exact to="/education" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Education</h5></li></NavLink>
                                <NavLink exact to="/professional" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Professional</h5></li></NavLink>
                                <NavLink exact to="/financial" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Financial</h5></li></NavLink>
                                <NavLink exact to="/manifacturing" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Manufacturing</h5></li></NavLink>
                                <NavLink exact to="/growingcompany" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Growing</h5></li></NavLink>
                                <NavLink exact to="/technology" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Technology</h5></li></NavLink>
                                <NavLink exact to="/insurance" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Insurance</h5></li></NavLink>
                                <NavLink exact to="/nonprofit" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Non-Profit</h5></li></NavLink>
                            </ul>
                        </li>
                        </NavLink>
                        <NavLink style={{ textDecoration: "none" }}><li><h4>Products</h4>
                            <ul className="ul">
                                <li><h5><ArrowRight />Laptop</h5></li>
                                <li><h5><ArrowRight />Desktop</h5></li>
                                <li><h5><ArrowRight />Server</h5></li>
                                <li><h5><ArrowRight />Network Rack</h5></li>
                            </ul>
                        </li></NavLink>
                        <NavLink exact to="contact" style={{ textDecoration: "none" }}><li><h4>Contact Us</h4>
                            <ul className="ul">
                                <NavLink exact to="/careers" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Careers</h5></li></NavLink>
                            </ul>
                        </li></NavLink>
                        <NavLink style={{ textDecoration: "none" }}><li><h4>Login</h4>
                            <ul classnName="ul" >
                                <NavLink exact to="/login" style={{ textDecoration: "none" }}> <li><h5><ArrowRight />Employee Login</h5></li></NavLink>
                                <NavLink exact to="/customer" style={{ textDecoration: "none" }}><li><h5><ArrowRight />Customer Login</h5></li></NavLink>
                            </ul>
                        </li></NavLink>

                    </ul>
                </nav>

                {/* <div className="whatsup">
                    <a href="http://wa.me/9627840399" aria-label="whatsapp" rel="noopener" target="_blank">
                        <img src={require("../images/whatsapp.png")} alt="whatsapp" />
                    </a>
                </div> */}
                <div  className="cont">
                    <h2>ITSC is a leading company<br />in IT solutions</h2>
                    <p>
                        We are authorized Partner of HP, DELL, APPLE, CISCO, MICROSOFT, INTEL, ASUS etc.
                    </p>
                </div>
            </div>

            </div>












            <div className="head-box">
                <h1>Our Services & Solutions</h1>
                <div className="bor">
                    <span></span>
                </div>
            </div>

            <div className="head-row">

                <div div className="head-slider-track1">
                    <NavLink exact to="/manageService" style={{ textDecoration: "none" }} >
                        <div className="head-slide1" >
                            <img src={require("../images/manage.png")} alt="service" />
                            <h5>Managed Services</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/comprehensive" style={{ textDecoration: "none" }}>
                        <div className="head-slide1">
                            <img src={require("../images/comp.png")} alt="service" />
                            <h5>Comprehensive IT-Support</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/remote-support" style={{ textDecoration: "none" }} >
                        <div className="head-slide1">
                            <img src={require("../images/remote.png")} alt="service" />
                            <h5>Remote Support & Maintenance</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/It-Consulting" style={{ textDecoration: "none" }} >
                        <div className="head-slide1">
                            <img src={require("../images/advisor.png")} alt="service" />
                            <h5>IT Consulting</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/It-Vendor" style={{ textDecoration: "none" }}>
                        <div className="head-slide1">
                            <img src={require("../images/vendor.png")} alt="service" />
                            <h5>IT Vendor Management </h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/infrastructure-support" style={{ textDecoration: "none" }}>
                        <div className="head-slide1">
                            <img src={require("../images/infra.png")} alt="service" />
                            <h5>Infrastructure Support Services</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/professionalserver" style={{ textDecoration: "none" }} >
                        <div className="head-slide1">
                            <img src={require("../images/server.png")} alt="service" />
                            <h5>Professional Server Support</h5>
                        </div>
                    </NavLink>

                    <NavLink exact to="/staffing" style={{ textDecoration: "none" }}>
                        <div className="head-slide1">
                            <img src={require("../images/staf.png")} alt="service" />
                            <h5>Staffing Services</h5>
                        </div>
                    </NavLink>
                </div>



            </div>
            <Patners />
            <Footer />

        </>


    )
}
export default Home;