import React from "react";
import "./Server.css";

const Server = ()=>{
    return(
        <>
          <div className="server">
            <div className="server-bg">
                <div className="server-text">
                <h1>Server</h1>
                </div>
            </div>
          </div>
        </>
    )
}
export default Server;