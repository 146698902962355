import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";



const Header = ()=>{
    return(
        <>
        <div>
            
            <nav className="xyz">
            <ul>
               
                <NavLink exact to="/" style={{textDecoration: "none"}}><li><h4>Home</h4></li></NavLink>
                <NavLink exact to="/about" style={{textDecoration: "none"}}><li><h4>About Us</h4></li></NavLink>
                <NavLink exact to="/services" style={{textDecoration: "none"}}><li><h4>Services</h4>
                <ul className="ul">
                      <NavLink exact to="/manageService" style={{textDecoration: "none"}}><li><h5><ArrowRight/>Managed </h5></li></NavLink>
                             <NavLink exact to="/comprehensive"style={{textDecoration: "none"}}><li><h5><ArrowRight/>Comprehensive </h5></li></NavLink>
                             <NavLink exact to="/remote-support"style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Remote </h5></li></NavLink>
                             <NavLink exact to="/It-Consulting"style={{textDecoration: "none"}}><li><h5><ArrowRight/>IT Consulting</h5></li></NavLink>
                             <NavLink exact to="/It-Vendor"style={{textDecoration: "none"}}><li><h5><ArrowRight/>IT Vendor </h5></li></NavLink>
                             <NavLink exact to="/infrastructure-support"style={{textDecoration: "none"}}><li><h5><ArrowRight/>Infrastructure </h5></li></NavLink>
                             <NavLink exact to="/professionalserver"style={{textDecoration: "none"}}><li><h5><ArrowRight/>Professional </h5></li></NavLink>
                             <NavLink exact to="/staffing"style={{textDecoration: "none"}}><li><h5><ArrowRight/>Staffing </h5></li></NavLink>
                          </ul>
                             </li></NavLink>    
                               <NavLink exact to="/solution" style={{textDecoration: "none"}}><li><h4>Solutions</h4></li></NavLink>
                                <NavLink exact to="/industries" style={{textDecoration: "none"}}><li><h4>Industries</h4>
                             <ul classsName="ul">
                                    <NavLink exact to="/education" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Education</h5></li></NavLink>
                                    <NavLink exact to="/professional" style={{textDecoration: "none"}}>  <li><h5><ArrowRight/>Professional</h5></li></NavLink>
                                     <NavLink exact to="/financial" style={{textDecoration: "none"}}>  <li><h5><ArrowRight/>Financial</h5></li></NavLink>
                                     <NavLink exact to="/manifacturing" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Manufacturing</h5></li></NavLink>
                                     <NavLink exact to="/growingcompany" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Growing</h5></li></NavLink>
                                     <NavLink exact to="/technology" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Technology</h5></li></NavLink>
                                     <NavLink exact to="/insurance" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Insurance</h5></li></NavLink>
                                     <NavLink exact to="/nonprofit" style={{textDecoration: "none"}}> <li><h5><ArrowRight/>Non-Profit</h5></li></NavLink>
                                 </ul>
                            </li>
                            </NavLink>
                            <NavLink style={{ textDecoration: "none" }}><li><h4>Products</h4>
                                       <ul classsName="ul">
                                          <li><h5><ArrowRight/>Laptop</h5></li>
                                         <li><h5><ArrowRight/>Desktop</h5></li>
                                         <li><h5><ArrowRight/>Server</h5></li>
                                        <li><h5><ArrowRight/>Network Rack</h5></li>
                                    </ul>
                      </li></NavLink>
                            <NavLink exact to="/contact" style={{textDecoration:"none"}}>
                                <li><h4>Contact Us</h4>
                           <ul classsName="ul">
                            <NavLink exact to="/careers" style={{textDecoration: "none"}}><li><h5><ArrowRight/>Careers</h5></li></NavLink>
                            </ul>
                </li></NavLink>
            </ul>
            </nav>
           
        </div>
        </>
    )
}

export default Header;