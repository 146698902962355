import { AcUnit } from '@mui/icons-material';
import React from 'react';
import Footer from './Footer';
import Header from './Header';
import "./Networking.css";

const Networking = ()=>{

    return(
        <>
        <Header/>
        <div className="net">
            <div className="net-bg">
                <div className="net-text">
                    <h1>Networking & Infrastructure Solutions</h1>
                </div>
            </div>
            <div className="net-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Networking and infrastructure solutions involve the design, implementation, and maintenance of a computer network within an organization. This may include local area networks (LANs), wide area networks (WANs), and wireless networks, as well as the hardware and software components that support them. Networking and infrastructure solutions are essential for enabling communication and data sharing within an organization.
                </p>
                <h5>Some examples of tasks that may be performed as part of networking and infrastructure solutions include:</h5>
               
                <div className="net-box1">
                <div className='net-ul' data-aos="flip-left" >
                    <div className='net-li'><h6><AcUnit className="ac"/>
                    Setting up and configuring network servers, routers, switches, and other equipment</h6></div>
                    <div className='net-li'><h6><AcUnit className="ac"/>
                    Installing and configuring network software, such as operating systems and applications</h6></div>
                    <div className='net-li'><h6><AcUnit className="ac"/>
                    Establishing network security measures, such as firewalls and user authentication</h6></div>
                    <div className='net-li'><h6><AcUnit className="ac"/>
                    Monitoring and troubleshooting network performance and issues</h6></div>
                     <div className='net-li'><h6><AcUnit className="ac"/>
                     Managing and maintaining network documentation, such as inventory lists and configuration records</h6></div>
                     <div className='net-li'><h6><AcUnit className="ac"/>
                     Implementing and managing virtualization solutions, such as virtual servers and virtual desktops</h6></div>       
                </div>  
                </div>
                <p>
                Networking and infrastructure solutions are often critical to the smooth operation of an organization, as they enable the flow of information and support many business processes.
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
       
}

export default Networking;