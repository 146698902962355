import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import "./Service.css";

const Services = ()=>{

    return(
        <>
        <Header/>
        <div className="serve">
            <div className="serve-bg">
                <div className="serve-text">
                    <h1>Services</h1>
                </div>
            </div>
            <div className="serve1"> 
                <div className="serve-box">
                    <div className="serve-row">
                        <div className="serve-slider">

                        <NavLink exact to="/manageService" style={{textDecoration: "none"}}>
                           <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/manage.png")} alt="service"/>
                                <h5>Managed Services</h5>
                                <div className="serve-block1">
                                    <h6>
                                        ITSC managed IT services can help eliminate your company's technology-based headaches.
                                    </h6>
                                      </div>
                            </div>
                           </NavLink>
                            
                             <NavLink exact to="/comprehensive"style={{textDecoration: "none"}}>
                             <div className="serve-block" data-aos="zoom-in">
                                <img src={require("../images/comp.png")} alt="service"/>
                                <h5>Comprehensive IT-Support</h5>
                                <div className="serve-block1">
                                    <h6>Computer, Laptop, Server, Printers, CCTV and network support for MSME.</h6>
                                      </div>
                            </div>
                             </NavLink>
                            
                        <NavLink exact to="/remote-support"style={{textDecoration: "none"}}>
                            <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/remote.png")} alt="service"/>
                                <h5>Remote Support & Maintenance</h5>
                                <div className="serve-block1">
                                    <h6> ITSC's proactive Remote Monitoring and 
                                      Maintenance solution is designed to remediate these issues.
                                   </h6>
                                      </div>
                            </div>
                            </NavLink>
                            
                            <NavLink exact to="/It-Consulting"style={{textDecoration: "none"}}>
                            <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/advisor.png")} alt="service"/>
                                <h5>IT Consulting</h5>
                                <div className="serve-block1">
                                    <h6>
                                    Providing thorough and complete IT roadmaps for small and mid-sized companies.
                                    </h6>
                                      </div>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                </div>

                 <div className="serve-box1">
                    <div className="serve-row">
                        <div className="serve-slider">
                        <NavLink exact to="/It-Vendor"style={{textDecoration: "none"}}>
                             <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/vendor.png")} alt="service"/>
                                <h5>IT Vendor Management </h5>
                                <div className="serve-block1">
                                    <h6>
                                    We understand the technology-latent issues that keep you up at night.
                                    </h6>
                                      </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/infrastructure-support"style={{textDecoration: "none"}}>
                            <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/infra.png")} alt="service"/>
                                <h5>Infrastructure Support Services</h5>
                                <div className="serve-block1">
                                    <h6>
                                Our services promote the implementation and management of a  efficient & dynamic computing infrastructure.
                                    </h6>
                                      </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/professionalserver"style={{textDecoration: "none"}}>
                            <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/server.png")} alt="service"/>
                                <h5>Professional Server Support</h5>
                                <div className="serve-block1">
                                    <h6>
                                    Your servers are the centre of your organizations computing infrastructure and need special attention.
                                    </h6>
                                      </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/staffing"style={{textDecoration: "none"}}>
                            <div className="serve-block" data-aos="zoom-in">
                            <img src={require("../images/staf.png")} alt="service"/>
                                <h5>Staffing Services</h5>
                                <div className="serve-block1">
                                    <h6>
                                    Helping organizations find the right pieces for their needs.
                                    </h6>
                                      </div>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
         <Footer/> 
        </>
    )
}

export default Services;