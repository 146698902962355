import React from "react";
import Footer from "./Footer";
import "./Manufacturing.css";
import Header from "./Header";

const Manufacturing = ()=>{
    return(
        <>
        <Header/>
        <div className="mani">
            <div className="mani-bg">
                <div className="mani-text">
                    <h1>Manufacturing</h1>
                </div>
            </div>
            <div className="mani-box"data-aos="zoom-in">
                <h3>Keep Your Manufacturing Business Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                Today’s manufacturing industry faces many challenges that impact strategic initiatives: global competition, regional expansion, complex supply chains, and new business model to name a few. To excel in this environment, manufacturers must stay innovative to improve operations and capture opportunities. StratusPointIT knows how to evaluate your operational needs to keep you competitive.
              </p>
            </div>
            <div className="mani-box1">
                <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="mani-box2">
                <div className="mani-row">
                    <div className="mani-slider">

                    <div className="mani-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on the IT, and <br/>you can focus on your clients.
                            </p>
                        </div>
                           <div className="mani-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor Authentication, Microsoft 365/Google Workspace Backup &<br/> Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                           <div className="mani-block">
                        <img src={require("../images/Monitoring-Services.png")} alt="industries"/>
                        <h6><b>Monitoring Services</b></h6>
                             <p>
                             Receive a comprehensive monitoring and remediation services for networks and servers, from triage, escalation and remediation of genuine alerts.
                             </p>
                        </div>
                        <div className="mani-block">
                            <img src={require("../images/Onsite.png")} alt="industries"/>
                             <h6><b>Onsite It Support</b></h6>
                             <p>
                             We not only provide remote IT support for your workstation, server, and network, we can also provide emergency onsite IT support across the contiguous US when needed.
                           </p>
                        </div>
                        <div className="mani-block">
                         <img src={require("../images/Backup-min.png")} alt="industries"/>
                         <h6><b>Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                             </p>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="mani-box3">
                <div className="mani-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="mani-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Manufacturing;