import { AcUnit } from "@mui/icons-material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Website.css";

const Website = ()=>{

    return(
        <>
        <Header/>
          <div className="web">
            <div className="web-bg">
                <div className="web-text">
                    <h1>Website & E-commerce Solutions</h1>
                </div>
            </div>
            <div className="web-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Website and e-commerce solutions involve the design, development, and maintenance of websites and online platforms that enable organizations to sell products or services online. 
                </p>
                <h5>These solutions can include:</h5>
                <div className="web-box1">
                    <div className="web-ul" data-aos="flip-left">
                       <div className="web-li"><h6><AcUnit className='ac'/>
                       <b>Website design and development:</b> creating and building a website that reflects the branding and messaging of an organization</h6></div>
                       <div className="data-li"><h6><AcUnit className='ac'/>
                       <b>Content management systems (CMS):</b> tools that enable users to create, edit, and publish content on a website</h6></div>
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        <b>E-commerce platforms:</b> software that enables organizations to sell products or services online, including features such as shopping carts,  <br/>
                        payment processing, and order management</h6></div>
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        <b>Marketing and SEO tools:</b> tools that enable organizations to optimize their website for search engines and track website traffic and performance</h6></div>
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        <b>Customer service and support tools: </b>tools that enable organizations to interact with and support customers online, such as live chat or email</h6></div>
                    </div>
                </div>
                <p>
                Website and e-commerce solutions are essential for organizations that want to establish an online presence or sell products or services online. They can help to improve customer engagement and sales, and provide a convenient and accessible way for customers to interact with an organization.
                </p>
            </div>
          </div>
          <Footer/>
        </>
    )
}

export default Website;