import React from 'react';
import "./Login.css";
import Header from "./Header";
import Footer from "./Footer";


const Login = ()=>{
    return(
        <>
        <Header/>
        <div className="log">
                <div className="log-form">
                    <div className="log-button">
                        <div id="btn"></div>
                         <button type='button' className='toggle-btn1'>Log In</button>
                    </div>
                    <form  id="login" className='input-group1'>
                    <input type="email" className="input-field1" placeholder="Email" required/>
                    <input type="text" className="input-field1" placeholder="Enter Password" required/>
                     <input type="checkbox" className="chech-box1"/><span>Remember Password</span>
                     <button type='submit' className='submit-btn1'>Log In</button>
                    </form>
                    
                </div>
                <div className="log-form1">
                    <div className="log-button">
                        <div id="btn"></div>
                         <button type='button' className='toggle-btn2'>Sign Up</button>
                    </div>
                    <form  id="signup" className='input-group1'>
                    <input type="email" className="input-field1" placeholder="Email" required/>
                    <input type="number" className="input-field1" placeholder="Contact Number" required/>
                    <input type="text" className="input-field1" placeholder="Enter Password" required/>
                     <input type="checkbox" className="chech-box1"/><span>Remember Password</span>
                     <button type='submit' className='submit-btn1'>Sign Up</button>
                    </form>
                    
                </div>
            </div>    
            <Footer/>    
        </>
       
    )
}
export default Login;