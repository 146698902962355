import React from 'react';
import "./BusinessManagement.css";

const BusinessManagement = ()=>{

    return(
        <>
        <h1>Business-Management</h1>
        
        </>
    )
       
}

export default BusinessManagement;