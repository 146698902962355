import React from "react";
import { Route, Routes } from "react-router-dom";
import Services from "./Service";
import Remote from "./Remote";
import Business from './Business';
import Asset from "./Asset";
import AMC from "./AMC";
import IT from "./IT";
import Backup from "./Backup";
import Laptop from "./Laptop";
import Desktop from "./Desktop";
import Server from "./Server";
import Networkrack from "./Networkrack";
import Industries from "./Industries";
import Education from "./Education";
import Professional from "./Professional";
import Financial from "./Financial";
import Manufacturing from "./Manufacturing";
import Growing from "./Growing";
import Technology from "./Technology";
import Insurance from "./Insurance";
import Nonprofit from "./Nonprofit";
import Home from "./Home";
import Aboutus from "./Aboutus";
import Solution from "./Solution";
import Contactus from "./Contactus"
import Header from "./Header";
import Login from "./Login";
import Customer from "./Customer";
import Careers from "./Careers";
import ManageService from "./ManageService";
import Comprehensive from "./Comprehensive";
import Consulting from "./Consulting";
import Vendor from "./Vendor";
import Infrastructure from "./Infrastructure";
import Professionalserver from "./Professionalserver";
import Staffing from "./Staffing";
import Networking from "./Networking";
import Data from "./Data";
import BusinessManagement from "./BusinessManagement";
import Cyber from  "./Cyber";
import ERP from "./ERP";
import Project from "./Project";
import CustomerManagement from "./CustomerManagement";
import Recovery from "./Recovery";
import Website from "./Website";

const Routing = ()=>{
    return(
        <Routes>
            <Route exact path="/header"element={<Header/>}/>
            <Route exact path="/"element={<Home/>}/>
            <Route exact path="/services"element={<Services/>}/>
            <Route exact path="/about"element={<Aboutus/>}/>
            <Route exact path="/solution"element={<Solution/>}/>
            <Route exact path="/contact"element={<Contactus/>}/>
            <Route exact path="/industries"element={<Industries/>}/>
            <Route excat path="/login"element={<Login/>}/>
            <Route excat path="/customer"element={<Customer/>}/>
            <Route exact path="/careers" element={<Careers/>} />
            <Route exact path="/remote-support"element={<Remote/>}/>
            <Route exact path="/business"element={<Business/>}/>
            <Route exact path="/IT-Asset"element={<Asset/>}/>
            <Route exact path="/amc"element={<AMC/>}/>
            <Route exact path="/it"element={<IT/>}/>
            <Route exact path="/backup"element={<Backup/>}/>
            <Route exact path="/laptop"element={<Laptop/>}/>
            <Route exact path="/Desktop"element={<Desktop/>}/>
            <Route exact path="/server"element={<Server/>}/>
            <Route exact path="/network-rack"element={<Networkrack/>}/>
            <Route exact path="/education"element={<Education/>}/>
            <Route exact path="/professional"element={<Professional/>}/>
            <Route exact path="/financial"element={<Financial/>}/>
            <Route exact path="/manifacturing"element={<Manufacturing/>}/>
            <Route exact path="/growingcompany"element={<Growing/>}/>
            <Route exact path="/technology"element={<Technology/>}/>
            <Route exact path="/insurance"element={<Insurance/>}/>
            <Route exact path="/nonprofit"element={<Nonprofit/>}/>
            <Route exact path="/manageService" element={<ManageService/>}/>
            <Route exact path="/comprehensive" element={<Comprehensive/>}/>
            <Route exact path="/It-Consulting"element={<Consulting/>}/>
            <Route exact path="/It-Vendor"element={<Vendor/>}/>
            <Route exact path="/infrastructure-support" element={<Infrastructure/>}/>
            <Route exact path="/professionalserver"element={<Professionalserver/>}/>
            <Route exact path="/staffing"element={<Staffing/>}/>      
            <Route exact path="/networking"element={<Networking/>}/> 
            <Route exact path="/datamanagement"element={<Data/>}/> 
            <Route exact path="/business"element={<BusinessManagement/>}/> 
            <Route exact path="/cybersecurity"element={<Cyber/>}/> 
            <Route exact path="/enterprise"element={<ERP/>}/> 
            <Route exact path="/customerManagement"element={<CustomerManagement/>}/>
            <Route exact path="/projectManagement" element={<Project/>}/>
            <Route exact path="/disasterRecovery" element={<Recovery/>}/>
            <Route exact path="/website" element={<Website/>}/>



        </Routes>
    )
}
export default Routing;