import React from "react";
import "./IT.css";

const IT = ()=>{
    return(
        <div className="it">
            <div className="it-bg">
                <div className="it-text">
                    <h1>IT Security Service</h1>
                </div>
            </div>
        </div>
    )
}
export default IT;