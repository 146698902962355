import { LocationOn, Mail, Phone } from "@mui/icons-material";
import axios from "axios";
import React from "react";
import "./Contactus.css";
import Footer from "./Footer";
import Header from "./Header";

const Contactus = ()=>{

        const handlerContacus=(e)=>{
        
          axios.post("http://localhost:3050/user")
        .then(async(res)=>{
            const data= await res.data;
              console.log(data);  
        })
        .catch(err => console.log(err))

   
    }

    return(
        <>
        <Header/>
        <div className="contact">
            <div className="contact-content">
                <h2>Contact Us</h2>
                <p>
                    Email us and keep upto date with our latest news
                </p>
            </div>
            <div className="contact-container">
                <div className="contact-info">
                    {/* <div className="contact-box">
                        <div className="contact-icon"><LocationOn/></div>
                        <div className="contact-text">
                            <h3>Address</h3>
                            <p></p>
                        </div>
                    </div> */}
                    <div className="contact-box">
                        <div className="contact-icon"><Phone/></div>
                        <div className="contact-text">
                            <h3>Phone</h3>
                            <p>+91-9527840399</p>
                        </div>
                    </div>
                    <div className="contact-box">
                        <div className="contact-icon"><Mail/></div>
                        <div className="contact-text">
                            <h3>Email</h3>
                            <p>support@itsc.co.in</p>
                        </div>
                    </div>
                </div>
                <div className="contactform">
                    <formc onsubmit={handlerContacus}>
                        <h2>Send Message</h2>
                        <div className="InputBox">
                            <input type="text" name="" required="required"/>
                            <span>Full name</span>
                        </div>
                        <div className="InputBox">
                            <input type="email" name="" required="required"/>
                            <span>Email</span>
                        </div>
                        <div className="InputBox">
                            <textarea required="required"></textarea>
                            <span>Type Your Message...</span>
                        </div>
                        <div className="InputBox">
                            <input type="submit" name="" value="Send"/>
                        </div>
                    </formc>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Contactus;