import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Professional.css";

const Professional = ()=>{
    return(
        <>
        <Header/>
        <div className="prof">
            <div className="prof-bg">
                <div className="prof-text">
                    <h1>Professional</h1>
                </div>
            </div>
            <div className="prof-box" data-aos="zoom-in">
                <h3>Professional Services</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                Professional services organizations are growing, and they need to be sure technology is there to support their operations. Because revenue depends on the hours billed, downtime is not an option. ITSC team with its broad engineering expertise can provide cloud, hybrid and on-premise options and national onsite IT support can help your organization meet the challenges of ever-changing technology landscape.
                </p>
            </div>
            <div className="prof-box1">
                <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="prof-box2">
                <div className="prof-row">
                    <div className="prof-slider">

                    <div className="prof-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on the IT, and you<br/> can focus on your clients.
                            </p>
                        </div>
                        <div className="prof-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor Authentication, Microsoft 365/Google Workspace<br/> Backup & Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                         <div className="prof-block">
                         <img src={require("../images/Backup-min.png")} alt="industries"/>
                         <h6><b>Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                            </p>
                        </div> 
                        <div className="prof-block">
                            <img src={require("../images/Onsite.png")} alt="industries"/>
                             <h6><b>Onsite IT Support</b></h6>
                             <p>
                             We not only provide remote IT support for your workstation, server, and network, we can also provide emergency onsite IT support across the contiguous US when needed.
                             </p>
                        </div> 
                        <div className="prof-block">
                            <img src={require("../images/Network-Upgrade.png")} alt="industries"/>
                             <h6><b>Network Upgrades</b></h6>
                             <p>
                             ITSC provides customized network upgrades that enhance reliability, performance and security of your office, in addition to vulnerability scans, firewall solutions, and log-based intrusion detection solutions.
                        </p>
                        </div>

                    </div>
                </div>
            </div>
            
            <div className="prof-box3">
                <div className="prof-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="prof-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Professional;