import { AcUnit } from '@mui/icons-material';
import React from 'react';
import "./Comprehensive.css";
import Footer from './Footer';
import Header from './Header';

const Comprehensive = ()=>{

    return(
        <>
        <Header/>
        <div className="compre">
            <div className="compre-bg">
                <div className="compre-text">
                    <h1>Comprehensive IT Support</h1>
                </div>
            </div>
            <div className="compre-box">
                <div className="bord">
                    <span></span>
                </div>
                <h2 data-aos="fade-up">Computer, Laptop, Server, Printers, CCTV and network support for MSME.</h2>
                <p>
                Downtime is a profit killer. At ITSC, we recognize that in order to maximize profitability, 
                your company’s IT has to be up-and-running. That’s why we provide comprehensive IT support to 
                small and midsized businesses. Our support services are delivered in an affordable, monthly 
                rate based on the hardware you have in place. Take advantage of optimal uptime and experience 
                for yourself the benefits that ITSC's managed IT and computer, Laptop, Server, Printers, CCTV 
                repair services present businesses like yours
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            <div className="compre-box1"data-aos="flip-left">
                <div className="ul1">
                  <div className='LI'><h5><AcUnit className="ac"/>Computer Hardware Support</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Software Licensing and Management</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Support Desk</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Monitor Desktops for Issues</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Manage Resources Efficiently</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Automatic Deployment of Patches and Security Updates</h5></div>
                  <div className='LI'><h5><AcUnit className="ac"/>Desktop Monitoring and Management Solutions</h5></div>
                </div>
                                          
            </div>
               
        </div>
        <Footer/>
        </>
    )
}

export default Comprehensive;