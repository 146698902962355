import React from "react";
import "./CustomerManagement.css";
import { AcUnit } from '@mui/icons-material';
import Footer from "./Footer";
import Header from "./Header";


const CustomerManagement =()=>{

    return(
        <>
        <Header/>
            <div className="customer">
                <div className="customer-bg">
                    <div className="customer-text">
                        <h1>Customer Relationship Management (CRM)Systems</h1>
                    </div>
                </div>
                <div className="customer-box">
                    <div className="bord">
                        <span></span>
                    </div>
                    <p>
                    Customer Relationship Management (CRM) systems are software applications or platforms that enable organizations to manage and analyze customer interactions and data throughout the customer lifecycle. The goal of CRM is to improve customer relationships and support business growth.
                    </p>
                    <h5>CRM systems typically include features such as:</h5>
                    <div className="customer-box1">
                        <div className="customer-ul"data-aos="flip-left">
                             <div className="customer-li"><h6><AcUnit className='ac'/>
                             <b>Contact management: </b>storing and organizing customer contact information</h6></div>
                             <div className="customer-li"><h6><AcUnit className='ac'/>
                             <b>Sales and marketing automation:</b> tracking and managing sales leads, campaigns, and customer interactions</h6></div>
                             <div className="customer-li"><h6><AcUnit className='ac'/>
                             <b>Customer service and support:</b> managing customer inquiries and complaints</h6></div>
                             <div className="customer-li"><h6><AcUnit className='ac'/>
                             <b>Analytics and reporting:</b> generating reports and insights on customer behavior and interactions</h6></div>
                        </div>
                    </div>
                    <p>
                    CRM systems can be used to support various customer-facing functions, such as sales, marketing, and customer service. They can help organizations to better understand their customers, improve communication and responsiveness, and increase customer loyalty. CRM systems may be deployed on-premises or accessed through the cloud.
                    </p>
                </div>
            </div>
            <Footer/>
        </>
    )
}
export default CustomerManagement;
