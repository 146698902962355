import React from "react";
import "./Desktop.css";

const Desktop = ()=>{
    return(
        <>
          <div className="desk">
            <div className="desk-bg">
                <div className="desk-text">
                    <h1>Desktop</h1>
                </div>
            </div>
          </div>
        </>
    )
}
export default Desktop;