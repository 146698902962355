import { AcUnit } from "@mui/icons-material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Professionalserver.css";

const Professionalserver= ()=>{
    return(
        <>
        <Header/>
          <div className="professionalserver">
            <div className="professionalserver-bg">
                <div className="professionalserver-text">
                    <h1>Professional Server Support</h1>
                </div>
            </div>
            <div className="professionalserver-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Your servers are the centre of your organization’s computing infrastructure, and need special attention to keep your operations coordinated and moving efficiently. ITSC offers comprehensive support for all of your organization’s IT, but specializes in remote and on-site server management and remediation services. Our knowledgeable and experienced technicians monitor and manage your servers, ensuring that they run efficiently and aren’t exposed to threats that could put your organization at risk. We provide this service for a monthly flat rate, eliminating the per-incident charges that would normally hinder your organization’s overall ability to remain profitable.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
               <div className="professionalserver-box1"data-aos="flip-left">
                   
                   <div className="ul1">
                   <div className="LI"><h5><AcUnit className="ac"/>Maximize Server Uptime</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Reduce Operational Costs</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Virtual Server Management</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>VMware Virtualization Services</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Create and Manage Virtual Machines</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Server Consulting and Best Practices</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Get Started with Microsoft Hyper-V Today</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Streamline Operations with a Virtualized Network</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Take Advantage of a Flexible Virtual Environment</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Consolidate Space and Resources with Virtualized Servers</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Increase Mobility with Virtualized Desktops and Applications</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Microsoft Hyper-V - Virtualization Software for Windows Server</h5></div>
                   </div>
               </div>
          </div>
          <Footer/>
        </>
    )
}
export default Professionalserver;