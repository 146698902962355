import { Adjust } from "@mui/icons-material";
import React from "react";
import "./AMC.css";
import Footer from "./Footer";
import Header from "./Header";

const AMC = ()=>{
    return(
        <>
        <Header/>
        <div className="amc">
            <div className="amc-bg">
                <div className="amc-text">
                    <h1>AMC(Annual Maintenance Contract)</h1>
                </div>
            </div>
            <div className="amc-box">
                <div className="bord1">
                    <span></span>
                </div>
                <p >
                    In The IT Industry ITCS Provides <b>Annual Maintenance Contract (AMC) </b>for Various services and product. Computer AMC service is a contract in which we take care of your computer after its warranty period has been expired, providing up to the mark onsite and remote support, for networks and computers under an Annual Maintainence Contract, ardent monitoring of the computer & network to identify, isolate and eliminate potential issues by best troubleshooting techniques.
                </p>
                <p>
                Striving to achieve this by focusing individually on each project and build a healthy relationship with our Customers. Our services including sales of assembled and branded computer, Network Cabling, Wi-Fi Installation, Firewall Installation, Data Security Solutions, Computer Hardware Maintenance, Anti-Virus Solutions. Our alliance with Hardware partners enables in providing best economic quotations.
                </p>
                <div className="bord1">
                    <span></span>
                </div>
                <h2>OUR COMPUTER AMC SERVICE INCLUDES:</h2>
                <div className="amc-box1"data-aos="zoom-in">
                   <div className="am"><h6><Adjust className="ad"/>Computer repair</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Monitor maintenance</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Add / Remove Computer Hardware</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Security Fixes, Patches, Service Packs</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Fixed annual rate to allow easy budgeting</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>On call Support, Tele- Support,Mail Support.</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>On Site/ Remote Support services and many more.</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>All Under One Roof to all your PC Repair Needs</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Network Troubleshooting Services based on Internet</h6></div>
                   <div className="am"><h6><Adjust className="ad"/>Preventative maintenance – periodic checks on your system before problems occur</h6></div>
               </div>
               <div className="bord1">
                    <span></span>
                </div>
                <h2>Our role to resolve</h2>
                <p>
                <b>Performance Issues:</b> We undergo periodical checking for the hardware under contract and update if any issues persist.
                </p>
                <p>
               <b> Networking:</b> We provide expertise technicians to configure all kinds of servers & routers, firewalls
                installations custom to your requirement and provide alterations in alignment as requested by the customer.
                </p>
                <p>
                <b>Facility Management:</b> We procure with hardware requested by the customer and change the malfunction parts undergoing warranty and configure for an effective run.
                </p>
                <div className="bord1">
                    <span></span>
                </div>
                <h2>We offer two types of Annual Maintenance Services (Computer AMC services):</h2>
                <p>
                <b>Non – Comprehensive Annual Maintenance Contract:</b> Non-comprehensive Computer AMC includes repair & services only with cost of defective to be borne by the customer.
                </p>
                <p>
                    <b>We provide AMC / MMC (Monthly maintenance contract) services for both Computers and Laptops.</b>
                </p>
                <div className="bord1">
                    <span></span>
                </div>                     
               </div>
        </div>
        <Footer/>
        </>
    )
}
export default AMC;
