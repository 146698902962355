import React from 'react';
import "./Customer.css";
import Header from "./Header";
import Footer from "./Footer";

const Customer = ()=>{

    s
    return(
        <>
         <Header/>
            <div className="cust">
                <div className="cust-form">
                    <div className="cust-button">
                        <div id="btn"></div>
                         <button type='button' className='toggle-btn1'>Log In</button>
                    </div>
                    <form  id="login" className='input-group1'>
                    <input type="email" className="input-field1" placeholder="Email" required/>
                    <input type="text" className="input-field1" placeholder="Enter Password" required/>
                     <input type="checkbox" className="chech-box1"/><span>Remember Password</span>
                     <button type='submit' className='submit-btn1'>Log In</button>
                    </form>
                    
                </div>
                <div className="cust-form1">
                    <div className="cust-button">
                        <div id="btn"></div>
                         <button type='button' className='toggle-btn2'>Sign Up</button>
                    </div>
                    <form  id="signup" className='input-group1'>
                    <input type="email" className="input-field1" placeholder="Email" required/>
                    <input type="number" className="input-field1" placeholder="Contact Number" required/>
                    <input type="text" className="input-field1" placeholder="Enter Password" required/>
                     <input type="checkbox" className="chech-box1"/><span>Remember Password</span>
                     <button type='submit' className='submit-btn1'>Sign Up</button>
                    </form>
                    
                </div>
               
            </div>
            <Footer/>
        
        
        </>
    )
}
export default Customer;