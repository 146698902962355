import { AcUnit } from "@mui/icons-material";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Staffing.css";

const Staffing= ()=>{
    return(
        <>
        <Header/>
        <div className="staf">
            <div className="staf-bg">
                <div className="staf-text">
                    <h1>Staffing Services</h1>
                </div>
            </div>
            <div className="staf-box">
                <div className="bord">
                    <span></span>
                </div>
                <h2>Helping organizations find the right pieces for their needs.</h2>
                <p>
                As a solutions provider, we pride ourselves on helping organizations mitigate their operational problems. One of the major problems most organizations have is finding the talent they need to make their endeavor a success. Not all positions are created equal and sometimes the difference between relevance and insignificance is filling a key position with just the right person.
                </p>
                <p>
                At ITSC, our professional consultants understand that, in many respects, the modern workforce has shifted. Outsourcing has become a completely viable option for all businesses, and with it, comes an increased demand for temporary workers, freelancers, and contractors. Since our whole platform is helping businesses like yours eliminate downtime and operational inefficiency, providing organizations options that work to mitigate their workforce problems comes naturally for us.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            <div className="staf-box1"data-aos="flip-left">
                <div className="ul1">
                    <div className="LI"><h5><AcUnit className="ac"/>Microsoft Certified Solutions Expert (MCSE)</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>IT Infrastructure Library (ITIL)</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Project Management Professional (PMP)</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Linux Professional Institute certification (LPIC)</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Red Hat Certified Engineer (RHCE)</h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>VMWare Certified Professional  - Data Centre Virtualization </h5></div>
                    <div className="LI"><h5><AcUnit className="ac"/>Comp TIA Server+, Network+, Security+</h5></div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Staffing;