import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Nonprofit.css";

const Nonprofit =()=>{

    return(
        <>
        <Header/>
         <div className="nonprofit">
            <div className="nonprofit-bg">
                <div className="nonprofit-text">
                    <h1>Non-profit</h1>
                </div>
            </div>
            <div className="nonprofit-box"data-aos="zoom-in">
                <h3>Keep Your Non-Profit Organization Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                Non-profit companies face many unique challenges: an environment of constant resource constraints, pressure to minimize operational, rigorous requirements for accounting, reporting and administrative management, to name a few. To meet these challenges, information systems must be easy to implement, simple and straightforward to use, and contribute to efficiency by streamlining operations to help cut costs. And that’s what we excel at!
               </p>
            </div>
            <div className="nonprofit-box1">
            <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="nonprofit-box2">
                <div className="nonprofit-row">
                    <div className="nonprofit-slider">

                    <div className="nonprofit-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on <br/>the IT, and you can focus on your clients.
                            </p>
                        </div>
                           <div className="nonprofit-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor <br/>Authentication, Microsoft 365/Google Workspace Backup & Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                        <div className="nonprofit-block">
                        <img src={require("../images/Hardware.png")} alt="industries"/>
                        <h6><b>Hardware Deployment</b></h6>
                             <p>
                             We provide our customers the technical expertise in assessing, recommending, sourcing, procuring, delivering, and installing on-premise or<br/>  cloud oriented hardware needs.
                           </p>
                        </div>
                        <div className="nonprofit-block">
                        <img src={require("../images/Advisory.png")} alt="industries"/>
                        <h6><b>Advisory Services</b></h6>
                             <p>
                             A value-add service to assist our customers through an ever-changing technology landscape, from cloud migrations and server/network<br/> support to implementing proper security protocols.
                            </p>
                        </div>
                        <div className="nonprofit-block">
                            <img src={require("../images/Backup-min.png")} alt="industries"/>
                             <h6><b> Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                          </p>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div><div className="nonprofit-box3">
                <div className="nonprofit-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="nonprofit-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>
            </div>
            <Footer/>
        </>
    )
}
export default Nonprofit;
