import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import "./Industries.css";
import Header from "./Header";

const Industries = ()=>{
    return(
        <>
        <Header/>
        <div className="indust">
            <div className="indust-bg">
                <div className="indust-text">
                    <h1>Industries</h1>
                </div>
            </div>
            <div className="indust1">
            <div className="indust-box">
                <div className="indust-row">
                    <div className="indust-slider">
                        <NavLink exact to="/education"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/education.png")} alt="industries"/>
                          <h5>Education</h5>
                    </div>
                    </NavLink>

                    <NavLink exact to="/professional"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/prof.png")} alt="industries"/>
                          <h5>Professional Services</h5>
                    </div>
                    </NavLink>

                    <NavLink exact to="/financial"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/financial.png")} alt="industries"/>
                          <h5>Financial</h5>                        
                    </div>
                    </NavLink>

                    <NavLink exact to="/manifacturing"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/mani.png")} alt="industries"/>
                          <h5>Manufacturing</h5>
                        </div>
                        </NavLink>
                </div>
                </div>
                </div>
                <div className="indust-box1">
                <div className="indust-row">
                    <div className="indust-slider">
                        <NavLink exact to="/growingcompany"style={{textDecoration: "none"}}>
                    <div className="indust-block" data-aos="zoom-in">
                        <img src={require("../images/grow.png")} alt="industries"/>
                          <h5>Growing Companies</h5>
                    </div>
                    </NavLink>

                    <NavLink exact to="/technology"style={{textDecoration: "none"}}>
                    <div className="indust-block" data-aos="zoom-in">
                        <img src={require("../images/tech.png")} alt="industries"/>
                          <h5>Technology</h5>
                    </div>
                    </NavLink>

                    <NavLink exact to="/insurance"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/insurance.png")} alt="industries"/>
                          <h5>Insurance</h5>                        
                    </div>
                    </NavLink>

                    <NavLink exact to="/nonprofit"style={{textDecoration: "none"}}>
                    <div className="indust-block"data-aos="zoom-in">
                        <img src={require("../images/non.png")} alt="industries"/>
                          <h5>Non-Profit</h5>
                        </div>
                        </NavLink>

                     </div>
                   </div>
                </div>
                </div>
                <Footer/>
            </div>
        
        </>
    )
}
export default Industries;