import { AcUnit } from "@mui/icons-material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Recovery.css";

const Recovery =()=>{

    return(
        <>
        <Header/>
        <div className="recover">
            <div className="recover-bg">
                <div className="recover-text">
                    <h1>Disaster Recovery & Business Continuity Planning</h1>
                </div>
            </div>
            <div className="recover-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Disaster recovery and business continuity planning involve creating and implementing strategies and procedures to ensure that an organization can continue to operate in the event of a disaster or disruption. Disasters can include natural disasters, such as earthquakes and hurricanes, as well as human-caused disasters, such as cyber attacks or power outages.
                </p>
                <h5>Some examples of disaster recovery and business continuity measures include:</h5>
                <div className="recover-box1">
                    <div className="recover-ul"  data-aos="flip-left">
                    <div className="recover-li"><h6><AcUnit className='ac'/>
                    Backing up data and systems regularly to enable recovery in the event of data loss</h6></div>
                        <div className="recover-li"><h6><AcUnit className='ac'/>
                        Implementing redundant systems and infrastructure to enable failover in the event of a failure</h6></div>
                        <div className="recover-li"><h6><AcUnit className='ac'/>
                        Establishing procedures for communicating with employees and stakeholders during a disruption</h6></div>
                        <div className="recover-li"><h6><AcUnit className='ac'/>
                        Testing disaster recovery and business continuity plans regularly to ensure their effectiveness</h6></div>
                        <div className="recover-li"><h6><AcUnit className='ac'/>
                        Identifying and securing alternative facilities or resources that can be used in the event that the primary facility or resources are unavailable</h6></div>
                       
                    </div>
                </div>
                <p>
                Disaster recovery and business continuity planning is an important aspect of risk management for organizations. It helps to protect against data loss, minimize downtime, and ensure that critical business processes can continue in the event of a disruption.
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Recovery;
