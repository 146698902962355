import React from "react";
import Footer from "./Footer";
import "./Growing.css";
import Header from "./Header";

const Growing =()=>{

    return(
        <>
        <Header/>
          <div className="grow">
            <div className="grow-bg">
                <div className="grow-text">
                    <h1>Growing Companies</h1>
                </div>
            </div>
            <div className="grow-box"data-aos="zoom-in">
                <h3>Keep Your Startup Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                As a startup, there are many important things to worry about, and IT should not be one of them. ITSC has worked with many growing companies to help alleviate some of the pressures and free up time. We help companies scale quickly, provide support and make sure the core focus stays on growth and product development.
                </p>
            </div>
            <div className="grow-box1">
            <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="mani-box2">
                <div className="mani-row">
                    <div className="mani-slider">

                    <div className="grow-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on <br/>the IT, and you can focus on your clients.
                            </p>
                        </div>
                           <div className="grow-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor <br/>Authentication, Microsoft 365/Google Workspace Backup & Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                           <div className="grow-block">
                        <img src={require("../images/Hardware.png")} alt="industries"/>
                        <h6><b>Hardware Deployment</b></h6>
                             <p>
                             We provide our customers the technical expertise in assessing, recommending, sourcing, procuring, delivering, and installing on-premise or<br/>  cloud oriented hardware needs.
                           </p>
                        </div>
                        <div className="grow-block">
                            <img src={require("../images/Advisory.png")} alt="industries"/>
                             <h6><b> Advisory Services</b></h6>
                             <p>
                             A value-add service to assist our customers through an ever-changing technology landscape, from cloud migrations and <br/>server/network support to implementing  proper security protocols.
                        </p>
                        </div>
                        <div className="grow-block">
                         <img src={require("../images/AWS-Azure.png")} alt="industries"/>
                         <h6><b>AWZ/Azure Consulting</b></h6>
                             <p>
                             We provide AWS & Microsoft Azure certified services that help you drive value through public cloud enablement.

                            </p>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="grow-box3">
                <div className="grow-sec">
                    <img src={require("../images/benefits-min.png")}alt="industries"/>
                </div>
                <div className="grow-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Growing;