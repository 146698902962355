import React from "react";
import "./Aboutus.css";
import Footer from "./Footer";
import Header from "./Header";

const Aboutus = ()=>{
    return(
        <>
        <div className="about">
        <Header/>
            <div className="about-bg">
                <div className="about-text">
                    <h1>About Us</h1>
                </div>
            </div>
            <div className="about-box">
                <h5>
                    We help businesses to build and scale their own dedicated teams at a 
                    competitive price in India
                 </h5>
                 <div className="bor1">
                    <span></span>
                 </div>
                 <p>100% Confidential and Secure</p>
            </div>
            <div className="about-box1">
                <h6>
                We build robust software solutions with highly creative and dedicated offshore teams!
                </h6>
                <p>
                We are in domains like Hardware Networking, Repair and Maintenance of Computer Laptop Server. We are the best in Managed IT Services & Solutions supplierand. We provide complete IT solutions ranging from structured cabling.ITSC (Information Technology Support Center) is PUNE biased company. Company core heart business is to serv all type of support related to Desktops, Laptops, Servers & related to IT biased products.
                ITSC Vision is serv all above mention services in All Over INDIA & make a LEDGENT in IT support stream. Here we give eyes to looks different way to see IT.
                 Currently all people/ company  having desktops or laptop at home or office. You know where is electronic products, they having their issues. 
                “AND HERE WE ARE TO SLOVE THAT ISSUES”                 
                 </p>
            </div>
            <div className="about-box2">
                <div className="about-sec">
                    <h5>Mission</h5>
                    <p>
                    The ITSC IT Service Desk is dedicated to providing high quality and efficient day-to-day 
                    IT support to our customers. We endeavour to deliver an informative and supportive service
                     on first point of contact that exceeds our customers’ expectations. We strive to 
                     continually improve our services by requesting and acting upon customer feedback.
                    </p>
                </div>
                <div className="about-sec1">
                    <img src={require("../images/mission.png")}alt="mission"/>
                </div>
            </div>
            <div className="about-box3">
                <div className="about-sec2">
                    <img src={require("../images/vision.jpg")} alt=" vision"/>
                </div>
                <div className="about-sec3">
                    <h5>Vision</h5>
                    <p>
                    To be an IT industry accredited Service Desk, to maximise first contact resolution for our
                     customers by providing effective, timely solutions by highly trained Service Desk staff.
                      With an enhanced IT experience we will support and collaborate with the ITSC community
                       to reach strategic goals.
                  </p>
                </div>
            </div>
            <div className="about-box4">
            <div className="about-sec4">
            <h4>Values</h4>
                <p>Our IT Service Desk values are taken from the Departmental values. <br/>
                    To deliver a valued customer experience we will:
                </p>
                  <ul>
                    <li>Give a warm welcome</li>
                    <li>Listen</li>
                    <li>Understand your needs</li>
                    <li>Keep you informed of progress</li>
                    <li>Learn to improve continually</li>
                    <li>Committed to the provision of equality and diversity & inclusion</li>
                    
                  </ul>
                </div>
                <div className="about-sec5">
                <img src={require("../images/value.jpg")} alt="value"/>

                </div>
             
            </div>
            <Footer/>
        </div>
        </>
    )
}

export default Aboutus;