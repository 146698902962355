import React from "react";
import "./Networkrack.css";

const Networkrack = ()=>{
    return(
        <>
           <div className="network-rack">
                  <div className="networkrack-bg">
                    <div className="networkrack-text">
                        <h1>Network Rack</h1>
                    </div>
                  </div>
           </div>
        </>
    )
}
export default Networkrack;