import React from "react";
import Footer from "./Footer";
import "./Insurance.css";
import Header from "./Header";

const Insurance =()=>{

    return(
        <>
        <Header/>
          <div className="insure">
            <div className="insure-bg">
                <div className="insure-text">
                    <h1>Insurance</h1>
                </div>
            </div>
            <div className="insure-box"data-aos="zoom-in">
                <h3>Keep Your Insurance Agency Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                Insurance is probably one of the most fiercely competitive industries. Agencies face stiff competitions from both large corporations and local firms, sometimes even individual agents. A customized and proactive IT support strategy can help you have a competitive edge over the competition. Let us worry about IT for you, so you can focus on what’s more important: running your business.
                   </p>
            </div>
            <div className="insure-box1">
            <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="insure-box2">
                <div className="insure-row">
                    <div className="insure-slider">

                    <div className="insure-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on <br/>the IT, and you can focus on your clients.
                            </p>
                        </div>
                           <div className="insure-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor <br/>Authentication, Microsoft 365/Google Workspace Backup & Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                        
                        <div className="insure-block">
                            <img src={require("../images/Backup-min.png")} alt="industries"/>
                             <h6><b> Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                          </p>
                        </div>
                        <div className="insure-block">
                        <img src={require("../images/Hardware.png")} alt="industries"/>
                        <h6><b>Hardware Deployment</b></h6>
                             <p>
                             We provide our customers the technical expertise in assessing, recommending, sourcing, procuring, delivering, and installing on-premise or<br/>  cloud oriented hardware needs.
                           </p>
                        </div>
                        <div className="insure-block">
                        <img src={require("../images/Advisory.png")} alt="industries"/>
                        <h6><b>Advisory Services</b></h6>
                             <p>
                             A value-add service to assist our customers through an ever-changing technology landscape, from cloud migrations and server/network<br/> support to implementing proper security protocols.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="insure-box3">
                <div className="insure-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="insure-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Insurance;
