import React from "react";
import "./Backup.css";
import Footer from "./Footer";
import Header from "./Header";

const Backup = ()=>{
    return(
        <>
        <Header/>
        <div className="backup">
            <div className="backup-bg">
                <div className="backup-text">
                    <h1>Backup Services</h1>
                </div>
            </div>
            <div className="backup-box">
                <h1>We offer two types of Backup Services:</h1>
                <div className="bord1">
                    <span></span>
                </div>
            </div>

            <div className="backup-box1">
                <div className="backup-row">
                    <div className="backup-slider">
                        
                        <div className="backup-block">
                            <img src={require("../images/acroni.png")} alt="backup"/>
                            <h5>Acronis Service</h5>
                        </div>
                        
                    
                        <div className="backup-block">
                        <img src={require("../images/veeam.png")} alt="backup"/>
                            <h5>Veenam Service</h5>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Backup;