import { AcUnit } from '@mui/icons-material';
import React from 'react';
import "./Consulting.css";
import Footer from './Footer';
import Header from './Header';

const Consulting=()=>{

    return(
        <>
        <Header/>
         <div className="consult">
            <div className="consult-bg">
                <div className="consult-text">
                     <h1>IT Consulting</h1>
                </div>
            </div>
            <div className="consult-box">
                <div className="bord">
                    <span></span>
                </div>
                <h2 data-aos="zoom-in">Providing thorough and complete IT roadmaps for small and mid-sized companies </h2>
                <p>
                Small and mid-sized businesses need to be efficient to be competitive. At ITSC, we deal in efficiency. We provide comprehensive IT consulting services to companies that are looking to leverage their company's IT into higher degrees of productivity, and ultimately, profitability
                <br/>
                In order to take your business to the next level, you'll need to cut out the redundancies in process and in cost. The most successful companies will acknowledge the best ways to get the most out of the resources that are available to them. If your company is looking to enhance efficiency, and with it, boost organizational bottom-line profits, consider our professional IT consulting service. We have the knowledge and the expertise necessary to make your technology work best for you.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            < div className="consult-box1"data-aos="flip-left">
                <div className="ul1">
                    <div className="LI"><h4><AcUnit className="ac"/>Flexible Solutions that Grow When You Do</h4></div>
                    <div className="LI"><h4><AcUnit className="ac"/>Professional Technology Consultants.</h4></div>
                </div>
                
            </div>
         </div>
         <Footer/>
        </>
    )
}

export default Consulting;