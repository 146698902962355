import { AcUnit } from "@mui/icons-material";
import React from "react";
import "./ERP.css";
import Footer from "./Footer";
import Header from "./Header";

const ERP =()=>{

    return(
        <>
        <Header/>
        <div className="erp">
            <div className="erp-bg">
                <div className="erp-text">
                    <h1>Enterprise Resource Planning (ERP)Systems</h1>
                </div>
            </div>
            <div className="erp-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Enterprise Resource Planning (ERP) systems are software applications that enable organizations to manage and integrate their business processes and functions, such as finance, accounting, HR, and operations. ERP systems provide a single, unified platform for storing and accessing data and information related to these processes, which can help to improve efficiency and decision making.
                </p>
                <h5>ERP systems typically include modules for various business functions, such as:</h5>
                <div className="erp-box1">
                    <div className="erp-ul" data-aos="flip-left">
                    <div className="erp-li"><h6><AcUnit className='ac'/>
                    <b>Financial management:</b> accounting, budgeting, and financial reporting</h6></div>
                    <div className="erp-li"><h6><AcUnit className='ac'/>
                   <b> Supply chain management: </b>procurement, inventory management, and logistics</h6></div>
                    <div className="erp-li"><h6><AcUnit className='ac'/>
                    <b>Human resources:</b> employee records, payroll, and benefits</h6></div>
                    <div className="erp-li"><h6><AcUnit className='ac'/>
                    <b>Customer relationship management:</b> sales, marketing, and customer service</h6></div>
                    <div className="erp-li"><h6><AcUnit className='ac'/>
                    <b>Project management:</b> task assignment, scheduling, and tracking</h6></div>
                    </div>
                </div>
                <p>
                    ERP systems can be customized to meet the specific needs and requirements of an organization. They may be deployed on-premises or accessed through the cloud. Implementing an ERP system can be a complex and time-consuming process, as it involves integrating and standardizing various business processes and systems. However, it can also provide significant benefits, such as improved efficiency, better decision making, and increased visibility into business operations.
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default ERP;
