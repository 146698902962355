import { AcUnit } from '@mui/icons-material';
import React from "react";
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "./Remote.css";

const Remote = ()=>{
    return(
        <>
        <Header/>
        <div className="remote">
            <div className="remote-bg">
                <div className="remote-text">
                    <h1>Remote Support & Maintenance</h1>
                </div>
            </div>
            <div className="remote-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Have you ever been stuck in a technological bind caused by a flaw in your business’s network 
                security? Organizations who aren’t prepared for the constant upkeep that a computing network 
                demands will quickly find themselves overwhelmed. Despite the difficulties that come with 
                technology management, maintenance is an absolutely vital task that must be fulfilled in order
                 to get the most from your hardware and software. ITSC's proactive Remote Monitoring and 
                 Maintenance solution is designed to remediate these issues for small and medium-sized businesses,
                  and make them more affordable than ever before.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            <div className="remote-box1"data-aos="flip-left">
                <div className="ul1">              
                   
                   <div className="LI"><h5><AcUnit className="ac"/> 24/7 Help Desk</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Affordable Fixed Rates</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Comprehensive Remote Monitoring</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Mitigate Risk With Remote Support</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Regular System Updates and Patches</h5></div>
                   <div className="LI"><h5><AcUnit className="ac"/>Real-Time Expertise Just a Phone Call Away</h5></div>
                </div>
                
            </div>
            <div className="remote-box2">
                <h2> More Services:</h2>
                    {/* <NavLink exact to="/backup"style={{textDecoration: "none"}}> */}
                        <div className="remote-block">
                            <img src={require("../images/backup.png")} alt="service"/>
                            <h5>Backup Services</h5>
                        </div>
                    {/* </NavLink>           */}
              </div>
        </div>
        <Footer/>
        </>
    )
}
export default Remote;