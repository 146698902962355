import React from "react";
import "./Careers.css";
import Footer from "./Footer";
import Header from "./Header";


const Careers = ()=>{
    return(
        <>
        <Header/>
        <div className="career">
        <div className="career-bg">
                <div className="career-text">
                    <h1>Career Path</h1>
                </div>
            </div>
            <div className="career-box">
                <h5>Apply Now For An Immediate Interview!</h5>
                 <div className="bor1">
                    <span></span>
                 </div>
                 <p>
                    If you are a true techie, love what you do, and want to work in a progressive environment, please click on the job below and apply NOW! We look forward to seeing you!
                 </p>
            </div>
              <div className="career-box1">
                <div className="career-form">
                    <div className="career-button">
                        <div id="btn"></div>
                        <button type="button" className="toggle-btn">Job Application</button>
                    </div>
                    <form className="career-input">
                        <input type="text" className="inputfil" placeholder="Full Name"/>
                        <input type="email" className="inputfil" placeholder="Email"/>
                        <input type="number" className="inputfil" placeholder="Contact Number"/>
                        <div className="label">
                        <label >Upload Your CV</label>
                        <input type="file" className="inputfile"/>
                        </div>
                       <button type="submit" className='submit-btn'>Apply Now</button>

                    </form>

                </div>
              </div>     
        </div>
        <div className="foot">
        <Footer/>          
        </div>

        </>
    )
}
export default Careers;





