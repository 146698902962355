import React from "react";
import "./Asset.css";

const Asset = ()=>{
    return(
        <div className="asset">
            <div className="asset-bg">
                <div className="asset-text">
                    <h1>IT Asset Management Services</h1>
                </div>
            </div>
        </div>
    )
}
export default Asset;