import { AcUnit } from "@mui/icons-material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Vendor.css";

const Vendor= ()=>{
    return(
        <>
        <Header/>
           <div className="vendor">
            <div className="vendor-bg">
                <div className="vendor-text">
                    <h1>IT Vendor Management</h1>
                </div>
            </div>
            <div className="vendor-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                We understand the technology-latent issues that keep you up at night. You may have questions such as, "How will I grow my business while minimizing expenses?", or, "How will I protect my data from situations like natural disasters and all the malicious entities out there?"      
                You aren't alone.
                </p>
               
                <p>
                Many business owners and executives at small and midsized businesses are tearing their hair out trying to come up with solutions for some of the most frustrating and time-consuming issues their company faces. At ITSC, we deal in solutions!
                </p>
                <p>
                What if we told you that we could supply you with consulting services, just like a Chief Information Officer, at a fraction of the cost? The IT professionals at ITSC can do just that. With our Virtual CIO service, we provide you with an IT roadmap aligned specifically to make your business more profitable.
                </p>
                <p >
                Our vendor management service works with third-party vendors to help you reduce bottom-line expenses and present you solutions on the determination of which hardware and software suppliers will work best to suit your needs. Our service ranges from a simple consultation to the more in-depth program of infrastructure management. In this way, our Virtual CIO offering is sure to be a valuable asset to rely on when it’s time to evaluate your company’s technology needs.
                </p>
                <div className="bord">
                    <span></span>
                </div>
            </div>
            <div className="vendor-box1"data-aos="flip-left">
                
                <div className="ul1">
                    <div className='LI'><h5><AcUnit className="ac"/>Reliability and Accessibility</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>Cost-Efficient and Predictable</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>Improve Your Business Operations</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>Increase Your Company's Efficiency</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>IT Vendor, Warranty & Hardware Management</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>Get the Support You Need with Co-Managed IT</h5></div>
                    <div className='LI'><h5><AcUnit className="ac"/>Augment Your Current IT Department with ITSC's IT Professionals</h5></div>

                </div>
                </div>    
               
           </div>
           <Footer/>
        </>
    )
}
export default Vendor;