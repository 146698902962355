import { AcUnit } from '@mui/icons-material';
import React from 'react';
import "./Data.css";
import Footer from './Footer';
import Header from './Header';

const Data = ()=>{

    return(
        <>
        <Header/>
         <div className="data">
            <div className="data-bg">
                <div className="data-text">
                    <h1>Data Management & Storage Solutions</h1>
                </div>
            </div>
            <div className="data-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Data management and storage solutions involve the design, implementation, and maintenance of systems that enable the storage, organization, and retrieval of data within an organization. These solutions are essential for enabling the efficient and effective use of data to support business operations and decision making.
                </p>
                <h5>Some examples of tasks that may be performed as part of data management and storage solutions include:</h5>
               
                  <div className="data-box1" data-aos="flip-left">
                <div className="data-ul" >
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        Designing and implementing databases to store and organize data</h6></div>
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        Setting up and configuring data storage systems, such as network-attached storage (NAS) or storage area networks (SANs)</h6></div>  
                        <div className="data-li"><h6><AcUnit className='ac'/>
                        Establishing data backup and recovery processes to protect against data loss</h6></div> 
                         <div className="data-li"><h6><AcUnit className='ac'/>
                         Implementing data security measures to protect against unauthorized access or tampering</h6></div> 
                         <div className="data-li"><h6><AcUnit className='ac'/>
                         Monitoring and optimizing data storage performance and capacity</h6></div> 
                         <div className="data-li"><h6><AcUnit className='ac'/>
                         Migrating data to new storage systems or platforms as needed</h6></div>
                </div>
                </div>
                <p >
                There are many different types of data management and storage solutions available, including both on-premises and cloud-based options. These solutions can vary in terms of the amount of storage capacity they offer, the level of security they provide, and the type of data they are designed to support.
                </p>
            </div>
         </div>
         <Footer/>
        </>
    )
       
}

export default Data;