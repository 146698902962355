import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Technology.css";

const Technology =()=>{

    return(
        <>
        <Header/>
         <div className="tech">
            <div className="tech-bg">
                <div className="tech-text">
                    <h1>Technology</h1>
                </div>
            </div>
            <div className="tech-box"data-aos="zoom-in">
                <h3>Keep Your Technology Company Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                The success of a technology company depends on its network’s stability and security at large. Whether you’re a startup company or a mature organization, your technology resources are mission-critical. Our approach to IT services focuses on delivering the highest levels of availability and security anytime, anywhere — from end-points such as laptops, desktops and smart-phones to servers, virtualization, cloud computing and IT optimization — we cover your entire network both on-premise and in the cloud.
                </p>
            </div>
            <div className="tech-box1">
            <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="tech-box2">
                <div className="tech-row">
                    <div className="tech-slider">

                    <div className="tech-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on <br/>the IT, and you can focus on your clients.
                            </p>
                        </div>
                           <div className="tech-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor <br/>Authentication, Microsoft 365/Google Workspace Backup & Recovery, Network security, and Web Protection via DNS filtering.
                            </p>
                        </div>
                        
                        <div className="tech-block">
                            <img src={require("../images/Monitoring-Services.png")} alt="industries"/>
                             <h6><b> Monitoring Services</b></h6>
                             <p>
                             A value-add service to assist our customers through an ever-changing technology landscape, from cloud migrations and <br/>server/network support to implementing  proper security protocols.
                             </p>
                        </div>
                        <div className="tech-block">
                         <img src={require("../images/AWS-Azure.png")} alt="industries"/>
                         <h6><b>AWZ/Azure Consulting</b></h6>
                             <p>
                             We provide AWS & Microsoft Azure certified services that help you drive value through public cloud enablement.
                            </p>
                        </div> 
                        <div className="tech-block">
                        <img src={require("../images/Hardware.png")} alt="industries"/>
                        <h6><b>Hardware Deployment</b></h6>
                             <p>
                             We provide our customers the technical expertise in assessing, recommending, sourcing, procuring, delivering, and installing on-premise or<br/>  cloud oriented hardware needs.
                           </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tech-box3">
                <div className="tech-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="tech-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Technology;
