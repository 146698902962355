import { AcUnit } from '@mui/icons-material';
import React from 'react';
import "./Cyber.css";
import Footer from './Footer';
import Header from './Header';

const Cyber = ()=>{

    return(
        <>
        <Header/>
        <div className="cyber">
            <div className="cyber-bg">
                <div className="cyber-text">
                    <h1>Cyber Security Solutions</h1>
                </div>
            </div>
            <div className="cyber-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Cybersecurity solutions are designed to protect against cyber threats, such as malware, hackers, and data breaches. These solutions can help organizations to protect their networks, systems, and data from damage or unauthorized access.
                </p>
                <h5>
                There are many different types of cybersecurity solutions available, including:
                </h5>
                <div className="cyber-box1">
                    <div className="cyber-ul" data-aos="flip-left">
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                        <b>Firewalls:</b> hardware or software systems that block unauthorized access to a network</h6></div>
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                        <b>Anti-virus and anti-malware software:</b> tools that detect and remove malware from systems</h6></div>
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                       <b> Encryption:</b> methods for encoding data to protect it from unauthorized access</h6></div>
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                        <b>Identity and access management:</b> systems that control and monitor access to resources</h6></div>
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                        <b>Network security:</b> measures to protect against threats to a network, such as denial of service attacks</h6></div>
                        <div className="cyber-li"><h6><AcUnit className='ac'/>
                        <b>Vulnerability management:</b> processes for identifying and mitigating vulnerabilities in systems and networks</h6></div>
                   </div>
                </div>
                      <p>
                      Implementing cybersecurity solutions is an important aspect of protecting an organization's information and assets. Cybersecurity professionals, such as security analysts and administrators, are responsible for designing and implementing these solutions.
                      </p>
            </div>
        </div>
         <Footer/>
        </>
    )
       
}

export default Cyber;