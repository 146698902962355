import React from "react";
import "./Education.css";
import Footer from "./Footer";
import Header from "./Header";

const Education = ()=>{
    return(
        <>
        <Header/>
          <div className="edu">
            <div className="edu-bg">
                <div className="edu-text">
                    <h1>Education</h1>
                </div>
            </div>
            <div className="edu-box" data-aos="zoom-in">
                <h2>IT Support For Schools</h2>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                ITSC, one of the nation’s top managed IT services companies, has been helping educational institutions. We provide your institution ways to maximize its technology investment, improve the technologies that drive it, and secure it against internal and external threats.
                </p>
            </div>
            <div className="edu-box1">
                <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
           
            <div className="edu-box2">
                <div className="edu-row">
                    <div className="edu-slider">

                        <div className="edu-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                                As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus <br/>on the IT, and you can focus on your students.
                            </p>
                        </div>
                        <div className="edu-block">
                            <img src={require("../images/IT-Security.png")} alt="industries"/>
                             <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor Authentication, Network security,<br/> and Web Protection via DNS filtering.
                                </p>
                        </div>
                        <div className="edu-block">
                            <img src={require("../images/Monitoring-Services.png")} alt="industries"/>
                             <h6><b>Monitoring Services</b></h6>
                             <p>
                             Receive a comprehensive monitoring and remediation services for networks and servers, from triage, escalation, and remediation of<br/> genuine alerts.
                            </p>
                        </div>
                        <div className="edu-block">
                            <img src={require("../images/Backup-min.png")} alt="industries"/>
                             <h6><b>Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                             </p>
                        </div>
                        <div className="edu-block">
                            <img src={require("../images/AWS-Azure.png")} alt="industries"/>
                             <h6><b>AWS/Azure Consulting</b></h6>
                             <p>
                             We provide AWS & Microsoft Azure certified services that help you drive value through public cloud enablement.
                          </p>
                        </div>
                    </div>
                </div>
            </div>
          
            <div className="edu-box3">
            <div className="edu-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="edu-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>A Security first approach to your IT environment.</li>
                    <li>Full end-to-end IT solutions, from helpdesk to access to Technology Advisors and hardware procurement.</li>
                  </ul>
                </div>
             
            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Education;