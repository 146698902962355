import { MailOutline ,ArrowForward, Facebook, LinkedIn} from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer =()=>{
    return(
        <>
       
        <footer>
            <div className="footer-bg">

            <div className="foot-row">
                <div className="foot-col">
                    <img src={require("../images/pentagon.png")}className="logo" alt="logo"/>
                    <p>
                    Information Technology Support Center Pvt.Lts,is a leading IT consulting 
                    company known for its innovative and robust digital solutions. We help you evolve your 
                    business into a profitable venture by using the unrivaled experience of our highly
                     qualified product development team. 
                    </p>
                </div>
                <div className="foot-col">
                    <h4>Office 
                        <div className="uderline">
                            <span></span>
                        </div></h4>
                    <h5>Information Technology Support Center Pvt.Ltd </h5>
                 
                    <p className="email-id">support@itsc.co.in</p>
                    <h5>+91-9527840399</h5>
                </div>
                <div className="foot-col">
                    <h4>Links
                    <div className="uderline">
                            <span></span>
                        </div>
                    </h4>
                    <NavLink exact to="/" style={{textDecoration: "none"}}><h6>Home</h6></NavLink>
                    <NavLink exact to="/about"style={{textDecoration: "none"}}><h6>About Us</h6></NavLink>
                    <NavLink exact to="/services"style={{textDecoration: "none"}}><h6>Services</h6></NavLink>
                    <NavLink exact to="/solution"style={{textDecoration: "none"}}><h6>Solutions</h6></NavLink>
                    <NavLink exact to="/product"style={{textDecoration: "none"}}><h6>Products</h6></NavLink>
                    <NavLink exact to="/contact"style={{textDecoration: "none"}}><h6>Contact Us</h6></NavLink>    
                </div>
            </div>
            <hr className="hr"/>
            <div className="foot-line">
            <p className="foot-copy">
                Copyright @ Information Technology Support Center Pvt.Ltd.2023.All Rights Reserved
              </p>

                    <div className="social-icons">
                        <Facebook className="si"/>
                        <LinkedIn className="si"/>
                    </div>
                    </div>
            </div>
            
        </footer>
        </>
    )
}
export default Footer;



    {/* <h4>More Information
                    <div className="uderline">
                            <span></span>
                        </div> </h4> */}
                    {/* <form>
                        <div className="form-bg">
                        <MailOutline className="icon"/>
                        <input type="email" placeholder="Enter Your Email-Id" required/>
                        <button type="submit"><ArrowForward className="icon1"/></button>
                        </div>
                    </form> */}
                    {/* <div className="social-icons">
                        <Facebook className="si"/>
                        <WhatsApp className="si"/>
                        <Twitter className="si"/>
                        <LinkedIn className="si"/>
                    </div> */}