import React from "react";
import "./Solution.css";
import Header from "./Header";
import Footer from './Footer';
import { NavLink } from "react-router-dom";

const Solution = ()=>{
    return(
        <>
        <Header/>
        <div className="solution">
            <div className="solution-bg">
                <div className="solution-text">
                    <h1>Solution</h1>
                </div>
            </div>
             <div className="solution1">
                <div className="solution-box">
                    <div className="solution-row">
                        <div className="solution-slider">

                            <NavLink exact to="/networking"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/infra.png")} alt="solution"/>
                                <h5>Networking &<br/> Infrastructure Solutions</h5>
                                <div className="solution-block1">
                                     <h6>
                                     Networking & infrastructure solutions involve the design, implementation & maintenance of a computer network.
                                     </h6>
                                    </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/datamanagement"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/data-management.png")} alt="solution"/>
                                <h5>Data Management &<br/> Storage Solutions</h5>
                                <div className="solution-block1">
                                    <h6>
                                    Data management & storage solutions involve the design, implementation & maintenance of systems.
                                    </h6>
                                    </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/business"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/business-management.png")} alt="solution"/>
                                <h5>Business Process <br/>Automation & Management Solutions</h5>
                                <div className="solution-block1">
                                    <h6>
                                    Business process automation & management solutions involve the use of technology.
                                    </h6>
                                    </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/cybersecurity"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/cyber-security.png")} alt="solution"/>
                                <h5>Cyber Security Solutions</h5>
                                <div className="solution-block1">
                                     <h6>
                                     Cybersecurity solutions are designed to protect against cyber threats, such as malware, hackers & data breaches. 
                                     </h6>
                                    </div>
                            </div>
                            </NavLink>

                        </div>
                    </div>
                </div>

                <div className="solution-box1">
                    <div className="solution-row">
                        <div className="solution-slider">

                        <NavLink exact to="/enterprise"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/resources.png")} alt="solution"/>
                                <h5>Enterprise Resource <br/>Planning (ERP)Systems</h5>
                                <div className="solution-block1">
                                    <h6>
                                    Enterprise Resource Planning (ERP) systems are software applications that enable organizations to manage.
                                    </h6>
                                </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/customerManagement"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/customer.png")} alt="solution"/>
                                <h5>Customer Relationship Management (CRM)<br/>Systems</h5>
                                <div className="solution-block1">
                                     <h6>
                                     Customer Relationship Management (CRM) systems are software applications or platforms that enable organizations to manage.
                                     </h6>
                                    </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/projectManagement"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/project.png")} alt="solution"/>
                                <h5>Project Management Solutions</h5>
                                <div className="solution-block1">
                                     <h6>
                                     Project management solutions are tools or platforms that enable organizations to plan, execute, and track projects.
                                     </h6>
                                    </div>
                            </div>
                            </NavLink>

                            <NavLink exact to="/disasterRecovery"style={{textDecoration: "none"}}>
                            <div className="solution-block" data-aos="zoom-in">
                            <img src={require("../images/recovery.png")} alt="solution"/>
                                <h5>Disaster Recovery & Business Continuity <br/>Planning</h5>
                                <div className="solution-block1">
                                     <h6>
                                     Disaster recovery & business continuity planning involve creating & implementing strategies
                                     </h6>
                                    </div>
                            </div>
                            </NavLink>

                        </div>
                    </div>
                </div>

                <div className="solution-box1">
                    <div className="solution-row">
                        <div className="solution-slider">
                                  
                                 <NavLink exact to="/website"style={{textDecoration: "none"}}>
                                  <div className="solution-block"data-aos="zoom-in">
                                  <img src={require("../images/web.png")} alt="solution"/>
                                    <h5>Website & E-commerce Solutions</h5>
                                    <div className="solution-block1">
                                        <h6>
                                        Website & e-commerce solutions involve the design, development & maintenance of websites & online platforms.
                                        </h6>
                                    </div>
                                  </div>
                                  </NavLink>

                        </div>
                    </div>
                </div>

             </div>
        </div>
        <Footer/>
        </>
    )
}

export default Solution;