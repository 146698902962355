import React from "react";
import "./Financial.css";
import Footer from "./Footer";
import Header from "./Header";

const Financial = ()=>{
    return(
        <>
        <Header/>
        <div className="fin">
            <div className="fin-bg">
                <div className="fin-text">
                    <h1>Financial</h1>
                </div>
            </div>
            <div className="fin-box"data-aos="zoom-in">
                <h3>Keep Your Financial Services Firm Connected</h3>
                <div className="bor1">
                    <span></span>
                </div>
                <p>
                With cyber-security being the number one concern, your organization needs a partner you can rely on. ITSC has a long history of working with financial services firms to help them maintain security and compliance, from FINRA, SOX, and SEC regulations.
                </p>
            </div>
            <div className="fin-box1">
                <h3>Products And Services:</h3>
                <div className="bor">
                    <span></span>
                </div>
            </div>
            <div className="fin-box2">
                <div className="fin-row">
                    <div className="fin-slider">

                    <div className="fin-block">
                            <img src={require("../images/Support-min.png")} alt="industries"/>
                             <h6><b>ITAnyWhere Support</b></h6>
                             <p>
                             As your business grows, so does your IT infrastructure, from your computers, networks, and servers. Let the experts focus on the IT, <br/>and you can focus on your clients.
                            </p>
                        </div>
                           <div className="fin-block">
                        <img src={require("../images/IT-Security.png")} alt="industries"/>
                        <h6><b>IT Security Services</b></h6>
                             <p>
                             From Advanced Phishing & Spam protection to Microsoft 365/Google Workspace Multi-Factor Authentication, Microsoft 365/Google Workspace <br/>Backup & Recovery, Network security, and Web Protection via DNS filtering.
                             </p>
                        </div>
                           <div className="fin-block">
                        <img src={require("../images/Monitoring-Services.png")} alt="industries"/>
                        <h6><b>Monitoring Services</b></h6>
                             <p>
                             Receive a comprehensive monitoring and remediation services for networks and servers, from triage, escalation and remediation of genuine alerts.
                              </p>
                        </div>
                        <div className="fin-block">
                         <img src={require("../images/Backup-min.png")} alt="industries"/>
                         <h6><b>Backup Services</b></h6>
                             <p>
                             From cost effective cloud backups, advanced disaster recovery solutions to Microsoft 365/Google Workspace solutions.
                            </p>
                        </div> 
                        <div className="fin-block">
                            <img src={require("../images/Network-Upgrade.png")} alt="industries"/>
                             <h6><b>Network Upgrades</b></h6>
                             <p>
                             ITSC provides customized network upgrades that enhance reliability, performance and security of your office, in addition to<br/> vulnerability scans, firewall  solutions, and log-based intrusion detection solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
               
            <div className="fin-box3">
                <div className="fin-sec">
                    <img src={require("../images/benefits-min.png")} alt="industries"/>
                </div>
                <div className="fin-sec1">
                <h4>Benefits:</h4>
                  <ul>
                    <li>Access to engineers with broad technical experience.</li>
                    <li>Cloud, On-Premise or Hybrid.</li>
                    <li>Worry-free guarantee – stop worrying about technology.</li>
                    <li>Onsite emergency coverage for all of your offices anywhere in the United States.</li>
                    <li>Vendor agnostic – we work with any technology that is right for your business.</li>
                    <li>Deep industry knowledge.</li>
                  </ul>
                </div>

            </div>
            <Footer/>
            </div>
        </>
    )
}
export default Financial;