import { AcUnit } from "@mui/icons-material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Project.css";

const Project =()=>{

    return(
        <>
        <Header/>
        <div className="project">
            <div className="project-bg">
                <div className="project-text">
                    <h1>Project Management Solutions</h1>
                </div>
            </div>
            <div className="project-box">
                <div className="bord">
                    <span></span>
                </div>
                <p>
                Project management solutions are tools or platforms that enable organizations to plan, execute, and track projects. These solutions can help organizations to define project scope, assign tasks and resources, track progress, and manage budgets and timelines.
                </p>
                <h5>Some examples of project management solutions include:</h5>
                <div className="project-box1">
                    <div className="project-ul" data-aos="flip-left">
                         <div className="data-li"><h6><AcUnit className='ac'/>
                         <b>Project management software:</b> tools that enable users to create project plans, assign tasks, track progress, and manage resources</h6></div>
                         <div className="data-li"><h6><AcUnit className='ac'/>
                        <b> Project portfolio management (PPM) software:</b> tools that enable organizations to prioritize and manage multiple projects and resources</h6></div>
                         <div className="data-li"><h6><AcUnit className='ac'/>
                         <b>Project portfolio management (PPM) software:</b> tools that enable organizations to prioritize and manage multiple projects and resources</h6></div>
                    </div>
                </div>
                <p>
                Project management solutions can be used for a wide range of projects, including IT projects, marketing campaigns, and product development. They can help organizations to improve efficiency and productivity, reduce errors and risk, and deliver projects on time and within budget. Project management professionals, such as project managers and coordinators, are responsible for planning and overseeing the use of these solutions.
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Project;
